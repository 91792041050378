//
// Login 1
// Pages SASS files are compiled into separate css files
//

// Initialization of global variables, mixins and functions
@import '../../init';

.login.login-1 {
  // Form modes
  .login-signin,
  .login_box_shadow {
    padding: 3rem;
    box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.1);
  }
  .login-signup,
  .login-forgot {
    display: none;
  }

  &.login-signin-on {
    .login-signup {
      display: none;
    }

    .login-signin {
      display: block;
    }

    .login-forgot {
      display: none;
    }
  }

  &.login-signup-on {
    .login-signup {
      display: block;
    }

    .login-signin {
      display: none;
    }

    .login-forgot {
      display: none;
    }
  }

  &.login-forgot-on {
    .login-signup {
      display: none;
    }

    .login-signin {
      display: none;
    }

    .login-forgot {
      display: block;
    }
  }
}

// Desktop view
@include media-breakpoint-up(lg) {
  .login.login-1 {
    .login-aside {
      width: 100%;
      max-width: 360px;
    }

    .logo-title {
      font-size: 1.3rem;
      font-weight: 400;
    }

    .login-form {
      width: 100%;
      max-width: 450px;
    }
  }
}

// Tablet and Mobile mode
@include media-breakpoint-between(lg, xl) {
  .login.login-1 {
    .login-aside {
      width: 100%;
      max-width: 360px;
    }
  }
}

// Tablet mode
@include media-breakpoint-down(md) {
  .login.login-1 {
    .login-form {
      width: 100%;
      max-width: 400px;
    }
  }
}

// Mobile mode
@include media-breakpoint-down(xs) {
  .login.login-1 {
    .login-form {
      width: 100%;
      max-width: 100%;
    }
  }
}
